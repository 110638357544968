import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import SEO from '../components/seo';
import {
  H2,
  H3,
  P2,
  B2,
  StyledLink,
  Badge,
  A,
  BackButton,
} from '../components/page-elements';

import Container from '../components/container';
import LinkIcon from '../images/svg/link.inline.svg';
import DocumentIcon from '../images/svg/document.inline.svg';
import Layout from '../components/layout';

const StyledContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing[20]};
  text-align: center;

  @media (min-width: 48em) {
    margin-top: ${({ theme }) => theme.spacing[48]};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing[32]};

  @media (min-width: 64em) {
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.spacing[40]};
  }
`;

const DataRow = styled(Row)`
  flex-direction: column-reverse;
  @media (min-width: 64em) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing[10]} 0;
  position: relative;

  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (min-width: 64em) {
    display: block;
    padding: 0 ${({ theme }) => theme.spacing[20]};
  }
`;

const DetailsContainer = styled.div`
  flex: 2;
  text-align: left;
`;

const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize['3xl']};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[200]};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing[3]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['4xl']};
  }
`;

const SectionTagLine = styled.h3`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.secondary[400]};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing[10]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;

const SectionBody = styled(P2)`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
`;

const StandardsLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing[5]};
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing[3]};
`;

// Static Bubble
const BubbleWrap = styled.div`
  width: 225px;
  height: 225px;
  margin: ${({ theme }) => theme.spacing[2]};
  background: white;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.boxShadow.xl};
  border: 35px solid white;
  display: block;

  span {
    position: absolute;
    will-change: transform, opacity;
    left: 50%;
    bottom: -85px;
    padding: 5px 12px;
    background: #ffffff;
    box-shadow: ${({ theme }) => theme.boxShadow.xl};
    border-radius: 50px;
    white-space: nowrap;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.secondary[200]};
    pointer-events: none;
    user-select: none;
    transform: translate(-50%, -15px);
    opacity: 0;
    transition: opacity, transform;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    z-index: 99;
  }
  &:hover {
    span {
      opacity: 0.9;
      transform: translate(-50%, 0);
    }
  }

  @media (min-width: 64em) {
    position: absolute;
  }
`;

const EsomarWrap = styled(BubbleWrap)`
  left: 55%;
  top: 50%;

  @media (min-width: 90.063em) {
    left: 65%;
  }
`;
const EfamroWrap = styled(BubbleWrap)`
  left: 20%;
  top: 15%;

  @media (min-width: 90.063em) {
    left: 30%;
  }
`;
const DPCWrap = styled(BubbleWrap)`
  left: 10%;
  top: 5%;

  @media (min-width: 90.063em) {
    left: 20%;
  }
`;
const MIWrap = styled(BubbleWrap)`
  left: 15%;
  top: 60%;

  @media (min-width: 90.063em) {
    left: 20%;
    top: 50%;
  }
`;
const MSWrap = styled(BubbleWrap)`
  left: 60%;
  top: 5%;
  border-width: 45px;
`;

const BubbleDiv = styled(Img)`
  width: 100%;
  height: 100%;
  background: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Standards = ({ data }) => {
  const assets = data.sanityStandardsPage;

  return (
    <>
      <Layout>
        <SEO title='Standards' />
        <StyledContainer>
          <H2>Standards</H2>
          <H3>
            All AIMRO members sign up to abide to the highest standards in the
            industry.
          </H3>
          <Row>
            <DetailsContainer>
              <SectionTitle>Standards</SectionTitle>
              <SectionTagLine>
                Highest quality research organisations
              </SectionTagLine>
              <SectionBody>
                AIMRO represents the highest quality research organisations in
                the Republic of Ireland. Each of the member companies subscribes
                to a common set of values, operating principles and codes of
                conduct. AIMRO are members of both EFAMRO and ESOMAR, the
                international bodies that set the best practice standards for
                our industry.
              </SectionBody>
              <SectionBody>
                All AIMRO member companies have signed to support and abide by
                the ESOMAR codes of conduct.
              </SectionBody>
              <SectionBody>
                As a result, you can be confident that AIMRO members operate to
                the highest standards of professional integrity and abide by
                best practice industry standards. ESOMAR's codes of conduct as
                well as our own standards for qualitative research and political
                polling are detailed below.
              </SectionBody>
              <StandardsLinks>
                <A href={assets.esomarURL} target='_blank'>
                  <Badge>
                    Esomar Guidelines <LinkIcon />
                  </Badge>
                </A>
                <A
                  href={assets.pollingStandards.asset.url}
                  download
                  target='_blank'
                >
                  <Badge>
                    Polling Standards <DocumentIcon />
                  </Badge>
                </A>
                <A
                  href={assets.qualStandards.asset.url}
                  download
                  target='_blank'
                >
                  <Badge>
                    Qualitative Standards <DocumentIcon />
                  </Badge>
                </A>
              </StandardsLinks>
            </DetailsContainer>
            <ImageContainer>
              <EsomarWrap>
                <BubbleDiv
                  fluid={assets.esomar && assets.esomar.asset.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
                <span>Esomar</span>
              </EsomarWrap>
              <EfamroWrap>
                <BubbleDiv
                  fluid={assets.efamro && assets.efamro.asset.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
                <span>Efamro</span>
              </EfamroWrap>
            </ImageContainer>
          </Row>
          <DataRow>
            <ImageContainer>
              <DPCWrap>
                <BubbleDiv
                  fluid={
                    assets.dataProtectionCommission &&
                    assets.dataProtectionCommission.asset.fluid
                  }
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
                <span>Data Protection Commission</span>
              </DPCWrap>
            </ImageContainer>
            <DetailsContainer>
              <SectionTitle>Data Protection</SectionTitle>
              <SectionTagLine>Top security standards</SectionTagLine>
              <SectionBody>
                AIMRO represents its members and the industry as a whole, with
                regard to best practice data protection requirements and
                procedures for our industry.
              </SectionBody>
              <SectionBody>
                We work closely with our UK partner the MRS, to liaise with the
                Irish Data Protection Commission, in order to develop guidelines
                and support on GDPR for members.
              </SectionBody>
            </DetailsContainer>
          </DataRow>
          <Row>
            <DetailsContainer>
              <SectionTitle>Industry liason & promotion</SectionTitle>
              <SectionTagLine>Representing the Industry.</SectionTagLine>
              <SectionBody>
                AIMRO liaises closely with the two main Marketing networks in
                Ireland, to develop and promote best practice use of data,
                market research, and insight development by marketing teams.
              </SectionBody>
              <SectionBody>
                Our members deliver training, support and primary research
                through both networks.
              </SectionBody>
            </DetailsContainer>
            <ImageContainer>
              <MIWrap>
                <BubbleDiv
                  fluid={
                    assets.marketingInstitute &&
                    assets.marketingInstitute.asset.fluid
                  }
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
                <span>Marketing Institute</span>
              </MIWrap>
              <MSWrap>
                <BubbleDiv
                  fluid={
                    assets.marketingSociety &&
                    assets.marketingSociety.asset.fluid
                  }
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
                <span>Marketing Society of Ireland</span>
              </MSWrap>
            </ImageContainer>
          </Row>
          <StyledLink to='/'>
            <BackButton>Return Home</BackButton>
          </StyledLink>
        </StyledContainer>
      </Layout>
    </>
  );
};
export const standardsPageQuery = graphql`
  {
    sanityStandardsPage {
      efamro {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      dataProtectionCommission {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      esomar {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      esomarURL
      marketingInstitute {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      marketingSociety {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      pollingStandards {
        asset {
          url
        }
      }
      qualStandards {
        asset {
          url
        }
      }
    }
  }
`;
export default Standards;
